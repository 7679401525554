import api from "@/service/api";

const state = () => ({
    saving: false,
    error: null,
})

const mutations = {
    setError(state, value) {
        state.error = value;
    },
    setSaving(state, value) {
        state.saving = value;
    },
}

const getErrorMessage = (error) => {
    if (
        error?.response?.data?.code === 'chargeFailed'
        && error?.response?.data?.message
    ) {
        return error.response.data.message;
    }

    return "There was an error charging your card, check your information and try again"
}

const actions = {
    chargeDefault({ commit }, { priceId }) {
        commit("setError", null);
        commit("setSaving", true);

        api
            .post("/authorize/chargeDefault", { priceId })
            .then(() => {
                commit("wallet/setPurchaseComplete", true, { root: true });
            })
            .catch((error) => {
                commit("setError", getErrorMessage(error));
            })
            .then(() => {
                commit("setSaving", false);
            });
    },
    transaction({ commit }, { priceId, card, year, month, code, postalCode, countryId, save }) {
        commit("setError", null);
        commit("setSaving", true);

        api
            .post("/authorize/transaction", { priceId, card, year, month, code, postalCode, countryId, save })
            .then(() => {
                commit("wallet/setPurchaseComplete", true, { root: true });
            })
            .catch((error) => {
                commit("setError", getErrorMessage(error));
            })
            .then(() => {
                commit("setSaving", false);
            });
    },
    // deprecated methods below
    createAndChargeCard({ commit }, { priceId, card, year, month, code, postalCode, countryId }) {
        commit("setError", null);
        commit("setSaving", true);

        api
            .post("/authorize/createAndChargeCard", { priceId, card, year, month, code, postalCode, countryId })
            .then(() => {
                commit("wallet/setPurchaseComplete", true, { root: true });
            })
            .catch((error) => {
                commit("setError", getErrorMessage(error));
            })
            .then(() => {
                commit("setSaving", false);
            });
    },
    chargeCard({ commit }, { priceId, userCreditCardId }) {
        commit("setError", null);
        commit("setSaving", true);

        api
            .post("/authorize/chargeCard", { priceId, userCreditCardId })
            .then(() => {
                // should not refer to wallet here
                commit("wallet/setPurchaseComplete", true, { root: true });
            })
            .catch((error) => {
                commit("setError", getErrorMessage(error));
            })
            .then(() => {
                commit("setSaving", false);
            });
    },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}
