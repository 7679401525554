<template>
    <div>
        <div class="p-3">
            <div class="text-center my-5">
                <brand-logo-splash />
            </div>

            <div v-if="method === 'email'">
                <login-email />
            </div>

            <div v-if="isLoggedIn">
                <login-has-user />
            </div>
            <div v-else>
                <div v-if="!method">
                    <div v-if="enableAppleLogin">
                        <login-apple />
                    </div>
                    <div v-if="enableGoogleLogin" class="mt-3">
                        <login-google />
                    </div>
                    <div class="mt-3">
                        <div
                            @click="$store.commit('login/setMethod', 'email')"
                            class="login-button bg-light border"
                        >
                            <div class="icon-block">
                                <font-awesome-icon
                                    icon="fa-duotone fa-envelope"
                                />
                            </div>
                            {{ actionLabel }}
                            {{ $t("with Email") }}
                            <div class="invisible icon-block">
                                <font-awesome-icon
                                    icon="fa-duotone fa-envelope"
                                    class="invisible"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="text-center mt-3">
                        <b-btn
                            to="/login"
                            v-if="currentMode === 'signup'"
                            variant="link"
                        >
                            Already have an account? Login here
                        </b-btn>
                        <b-btn to="/signup" v-else variant="link">
                            Need to signup? Create an account
                        </b-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* global device */

import { mapGetters, mapState } from "vuex";
import { LAST_LOGIN_METHOD } from "@/constant/localStorage";

import BrandLogoSplash from "@/components/brand/BrandLogoSplash";
import LoginEmail from "@/components/login/LoginEmail";
import LoginGoogle from "@/components/login/LoginGoogle";
import LoginApple from "@/components/login/LoginApple";
import LoginHasUser from "@/components/login/LoginHasUser";

export default {
    created() {
        this.$store.dispatch("attribution/loadRecaptchaOnce");

        this.$router.onReady(() => {
            if (this.$route.query?.landing) {
                this.$store.commit(
                    "attribution/setLandingPage",
                    this.$route.query?.landing
                );
            }

            if (this.$route.query?.source) {
                this.$store.commit(
                    "attribution/setSource",
                    this.$route.query?.source
                );
            }

            if (this.$route.query?.referer) {
                this.$store.commit(
                    "attribution/setReferer",
                    this.$route.query?.referer
                );
            }

            if (this.$route.query?.gclid || this.$route.query?.msclkid) {
                this.$store.commit(
                    "attribution/setClick",
                    document.location.href
                );
            }

            if (this.$route.query?.invite) {
                this.$store.commit(
                    "attribution/setInvite",
                    this.$route.query?.invite
                );
            }

            if (this.$route.query?.expatMode) {
                this.$store.commit("attribution/setExpatMode", true);
            }

            if (this.$route.query?.session) {
                this.legacySession(this.$route.query.session);
            }

            // user cannot go active, wont work
            // if (this.$route.query?.anonymousSignup) {
            //     this.anonymousSignup();
            // }
        });
    },
    data() {
        return {
            recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            lastLoginMethod: localStorage.getItem(LAST_LOGIN_METHOD),
        };
    },
    computed: {
        currentMode() {
            return this.$route.meta.mode;
        },
        actionLabel() {
            if (this.currentMode === "signup") {
                return this.$t("Signup");
            }

            return this.$t("Login");
        },
        ...mapState("login", ["method"]),
        ...mapState("system", ["cordova"]),
        ...mapState("user", ["loggedOut"]),
        ...mapGetters("user", ["isLoggedIn"]),
        enableGoogleLogin() {
            if (this.lastLoginMethod && this.lastLoginMethod !== "google") {
                return false;
            }

            return true;
        },
        enableAppleLogin() {
            if (!this.cordova) {
                return false;
            }

            if (device.platform !== "iOS") {
                return false;
            }

            if (this.lastLoginMethod && this.lastLoginMethod !== "apple") {
                return false;
            }

            return true;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        legacySession() {
            this.api.post("/signup/legacySession").then((response) => {
                if (response?.data?.authToken) {
                    this.$store
                        .dispatch("user/login", response.data.authToken)
                        .then(() => {
                            this.$router.push({ name: "defaultPage" });
                        });
                }
            });
        },
        anonymousSignup() {
            this.api.post("/signup/anonymous").then((response) => {
                if (response?.data?.authToken) {
                    this.$store
                        .dispatch("user/login", response.data.authToken)
                        .then(() => {
                            this.$router.push({ name: "defaultPage" });
                        });
                }
            });
        },
    },
    components: {
        BrandLogoSplash,
        LoginEmail,
        LoginHasUser,
        LoginGoogle,
        LoginApple,
    },
};
</script>