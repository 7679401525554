import { createClient } from '@supabase/supabase-js'
import supabaseStorage from "@/utility/supabaseStorage";


const state = () => ({
    enabled: false,
    onlineUserIds: [],
})

const mutations = {
    setEnabled(state, value) {
        state.enabled = value
    },
    addOnlineUserId(state, userId) {
        if (!state.onlineUserIds.includes(userId)) {
            state.onlineUserIds.push(userId)
        }
    },
    removeOnlineUserId(state, userId) {
        state.onlineUserIds = state.onlineUserIds.filter((id) => id !== userId)
    },
    setOnlineUserIds(state, userIds) {
        state.onlineUserIds = userIds
    },
}

const actions = {
    disable({ state, commit }) {
        if (!state.enabled || !supabaseStorage.channel) {
            return;
        }

        supabaseStorage.channel.unsubscribe();
        supabaseStorage.channel = null

        commit('setEnabled', false)
    },
    enable({ state, commit, rootState }) {
        if (state.enabled) {
            return;
        }

        if (!supabaseStorage.client) {
            supabaseStorage.client = createClient(
                rootState.options.supabase.url,
                rootState.options.supabase.anonKey
            );
        }

        commit('setEnabled', true)

        supabaseStorage.channel = supabaseStorage.client.channel('globalOnlineStatus', {
            config: {
                presence: {
                    key: rootState.onboarding.user.id
                }
            }
        })

        supabaseStorage.channel
            .on('presence', { event: 'sync' }, () => {
                const newState = supabaseStorage.channel.presenceState();

                const newOnlineUserIds = Object.values(newState)
                    .flatMap((presences) => presences.map((p) => p.userId))
                    .filter(Boolean);

                commit('setOnlineUserIds', newOnlineUserIds);
            })
            .on('presence', { event: 'join' }, ({ newPresences }) => {
                newPresences.forEach((p) => {
                    commit('addOnlineUserId', p.userId)
                })
            })
            .on('presence', { event: 'leave' }, ({ leftPresences }) => {
                leftPresences.forEach((p) => {
                    commit('removeOnlineUserId', p.userId)
                })
            })
            .subscribe((status) => {
                if (rootState.onboarding.user.stealthMode) {
                    return;
                }

                if (status === 'SUBSCRIBED') {
                    supabaseStorage.channel.track({
                        userId: rootState.onboarding.user.id
                    })
                }
            })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
