<template>
    <div>
        <user-between-status :user="profile" :alert="true" />
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserBetweenStatus from "@/components/user/UserBetweenStatus";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("brand", ["brand"]),
        ...mapState("pusher", ["onlineUserIds"]),
        ...mapState("profile", ["profile"]),
    },
    components: {
        UserBetweenStatus,
    },
};
</script>