<template>
    <div
        v-if="!onboardingUser.censored"
        @click="viewWallet"
        class="d-flex justify-content-center align-items-center border rounded-pill px-2 py-1 fake-link"
    >
        <span class="small font-weight-bold px-1 mr-1">
            <span>{{ $t("Wallet") }}</span>
            <span v-if="$screen.md" class="ml-1">{{ $t("Balance") }}</span>
        </span>
        <b-badge v-if="balance" pill variant="light" :class="balanceClass">
            <font-awesome-icon icon="fa-duotone fa-coin" class="text-warning" />
            {{ balance }}
        </b-badge>
        <b-badge v-else variant="danger" pill>
            <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
            {{ $t("Empty") }}
        </b-badge>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        balanceClass() {
            if (this.onboardingUser?.creator) {
                return "border border-light";
            }

            if (this.balance >= 100) {
                return "border border-light";
            }

            if (this.balance < 25) {
                return "border border-danger text-danger";
            }

            if (this.balance < 100) {
                return "border border-warning text-warning";
            }

            return "";
        },
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        viewWallet() {
            this.$store.commit("wallet/reset");
            this.$store.commit("interface/setUserWalletModalVisible", true);
        },
    },
};
</script>