<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" to="/earnings">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("Back") }}
        </b-btn>

        <withdraw-types />
        <withdraw-history class="mt-3" />
    </generic-page>
</template>

<script>
import WithdrawTypes from "@/components/withdraw/WithdrawTypes";
import WithdrawHistory from "@/components/withdraw/WithdrawHistory";

export default {
    components: {
        WithdrawTypes,
        WithdrawHistory,
    },
};
</script>