<template>
    <div>
        <profile-conversation-messages />
    </div>
</template>

<script>
import ProfileConversationMessages from "@/components/profile/conversation/ProfileConversationMessages";

export default {
    components: {
        ProfileConversationMessages,
    },
};
</script>
