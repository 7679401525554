g<template>
    <div>
        <div v-if="loading">
            <generic-loading />
        </div>
        <div v-else>
            <div v-if="withdraw">
                <withdraw-pending />
            </div>
            <div v-else>
                <div class="bg-white p-3">
                    <div
                        class="d-flex align-items-center justify-content-between mb-2"
                    >
                        <h5>
                            {{ $t("Withdrawal Methods") }}
                        </h5>
                        <wallet-balance />
                    </div>

                    <b-alert variant="danger" show v-if="error">
                        {{ error }}
                    </b-alert>
                    <b-alert
                        v-else-if="hasAvailableMethods"
                        variant="primary"
                        show
                    >
                        {{ $t("Select your preferred withdrawal method") }}
                    </b-alert>
                    <b-alert
                        v-else-if="
                            !onboardingUser.guideHowDoIGetMyMoneyComplete
                        "
                        variant="warning"
                        show
                    >
                        <h5>
                            {{ $t("How do I get my money?") }}
                        </h5>
                        <div>
                            {{
                                $t(
                                    "Once you reach 100% progress on any withdrawal method you will be able to enter your banking or wallet details and proceed to withdraw your earnings"
                                )
                            }}
                        </div>
                        <b-btn
                            class="mt-3"
                            @click="dismissWithdrawExplainer"
                            variant="primary"
                            pill
                        >
                            {{ $t("Ok, got it") }}
                        </b-btn>
                    </b-alert>

                    <b-overlay :show="creating" spinner>
                        <div :key="type.key" v-for="type in types" class="mt-3">
                            <b-btn
                                @click="previewWithdraw(type)"
                                variant="light"
                                :disabled="!type.available"
                                block
                                size="lg"
                            >
                                <h5>
                                    {{ type.name }}
                                </h5>

                                <coin-badge
                                    class="bg-white mx-1"
                                    :label="$t('Minimum')"
                                    :amount="type.minimum"
                                />

                                <b-badge
                                    v-if="type.available"
                                    pill
                                    variant="primary"
                                    class="mx-1"
                                >
                                    {{ $t("Available") }}
                                </b-badge>
                                <b-badge
                                    v-else
                                    pill
                                    variant="light"
                                    class="bg-white mx-1"
                                >
                                    {{ $t("Progress") }}
                                    {{ type.progress }}%
                                </b-badge>

                                <div class="mt-3">
                                    <b-progress style="height: 5px" :max="100">
                                        <b-progress-bar :value="type.progress">
                                        </b-progress-bar>
                                    </b-progress>
                                </div>
                            </b-btn>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import WalletBalance from "@/components/utility/WalletBalance";
import WithdrawPending from "@/components/withdraw/WithdrawPending";

export default {
    data() {
        return {
            creating: false,
            loading: false,
            types: null,
            error: null,
        };
    },
    computed: {
        hasAvailableMethods() {
            return this.types?.filter((type) => type.available)?.length;
        },
        ...mapState("withdraw", ["withdraw"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    mounted() {
        this.loading = true;

        this.$store.dispatch("withdraw/refresh");

        this.api
            .post("/withdraw/types")
            .then((response) => {
                this.types = response.data.types;
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        dismissWithdrawExplainer() {
            this.api
                .post("/userFlag/guideHowDoIGetMyMoneyComplete")
                .then(() => {
                    this.$store.dispatch("onboarding/status");
                });
        },
        previewWithdraw(type) {
            this.error = null;
            this.creating = true;

            this.api
                .post("/withdraw/create", {
                    type: type.key,
                })
                .then((response) => {
                    if (response?.data?.withdraw) {
                        this.$router.push({
                            name: "withdrawCreate",
                            params: {
                                id: response.data.withdraw.id,
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error?.response?.data?.code === "insufficientFunds") {
                        this.error = error.response.data.message;
                    }
                })
                .finally(() => {
                    this.creating = false;
                });
        },
    },
    components: {
        WalletBalance,
        WithdrawPending,
    },
};
</script>